// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './authentication'
import transactions from '@src/views/pages/invoices/store'
import transactionsError from '@src/views/pages/invoices-error/store'

const rootReducer = { navbar, layout, auth, transactions, transactionsError }

export default rootReducer
