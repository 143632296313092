export const users = [
  {
    id: 1,
    companyId: 'rubridoc',
    userName: 'crediq_usr_api', // 'rubridoc',
    password: 'AAxLFyFD95@%WwV3PFiMFWgE$nL3JMWrK!@' // 'Temporal123!'
  },
  {
    id: 2,
    companyId: 'qa',
    userName: 'quality_assurance_usr_api',
    password: 's6rq7BQGpdQKCGmmsnjpkf'
  },
  {
    id: 3,
    companyId: 'leasing',
    userName: 'leasing_usr_api',
    password: 's6rq7BQGpdQKCGmmsnjpkf'
  }
]
